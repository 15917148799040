@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
}

.opacity-0 {
    opacity: 0;
}

.animate-fade-in {
  animation: fadeIn 3s ease-out forwards;
}

.bounce-animation {
  animation: bounce 2s infinite ease-in-out;
}